import { InformationFilled32 } from '@carbon/icons-react';
import { Form, Grid, Row } from 'carbon-components-react';
import { KcContextBase } from 'keycloakify';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '../../Component/Button/Button';
import { ButtonLinkGtm } from '../../Component/ButtonLinkGtm/ButtonLinkGtm';
import { ContentHeader } from '../../Component/ContentHeader/ContentHeader';
import { NewHeader } from '../../Component/NewHeader/NewHeader';
import Input from '../../Component/Input/Input';
import KeepLogged from '../../Component/KeepLogged/KeepLogged';

import { useSEO } from '../../hook/useSEO';
import { getParams } from '../../index';
import { TagManagerUtils, ActionType, UserLoggedStatus } from '../../TagManagerUtils';
import './Login.scss';
import { InputPartners } from '../../Component/InputPartners';
import { useChannel } from '../../hook/useChannel';
import { NewFooter } from '../../Component/NewFooter/NewFooter';

function Login(props: KcContextBase.Login) {
    const url = window.location.href;
    const { subtitle, isWebView } = getParams[0];

    const { channel } = useChannel();

    // eslint-disable-next-line prefer-const
    const [financiamento, setFinanciamento] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [disable, setDisable] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [metaTags, setMetaTags] = useState<any>({});

    const isAdvisoryChannel = channel === 'parceiros';

    const errorMsg = useMemo(() => {
        return { ...props.message, error: null };
    }, [props.message]);

    const getSeoHook = useSEO('pageLogin');

    useEffect(() => {
        const { data, loading } = getSeoHook.getSEO();
        if (!loading) {
            setMetaTags(data?.pageLogin?.seo);
        }
    }, [getSeoHook]);

    useEffect(() => {
        if (!url.includes('cadastrar=true')) {
            TagManagerUtils.pageview(
                ActionType.PageView,
                'login',
                UserLoggedStatus.LoggedOut,
                'e-agro/login',
                TagManagerUtils.getActionLocation(),
            );
        }
    }, []);

    const onSubmit = () => {
        // console.log('onSubmit');
    };

    useEffect(() => {
        if (username.trim() && password) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }, [username, password]);

    useEffect(() => {
        if (url.includes('cadastrar=true')) {
            window.location.href = `${props.url.registrationUrl}&subtitle=${subtitle}${
                isWebView ? '&isWebView=true' : ''
            }`;
        }

        if (url.includes('kc_action=UPDATE_PASSWORD')) {
            setFinanciamento(true);
        }
    }, [url, props.url]);

    useEffect(() => {
        if (errorMsg.type === 'error') {
            setHasError(true);
        }
    }, [errorMsg.type]);

    function onClickEntrar() {
        TagManagerUtils.interact(
            ActionType.Click,
            'botao',
            'botao-entrar',
            'login',
            UserLoggedStatus.LoggedOut,
            'e-agro/login',
        );
    }

    function onClickEsqueciMinhaSenha() {
        TagManagerUtils.interact(
            ActionType.Click,
            isAdvisoryChannel ? 'botao' : 'hiper-link',
            'esqueci-minha-senha',
            'login',
            UserLoggedStatus.LoggedOut,
            'e-agro/login',
        );
    }

    function onClickCadastro() {
        TagManagerUtils.interact(
            ActionType.Click,
            isAdvisoryChannel ? 'botao' : 'hiper-link',
            'cadastre-se',
            isAdvisoryChannel ? 'cadastro' : 'login',
            UserLoggedStatus.LoggedOut,
            'e-agro/login',
        );
    }

    const channelTypePhrase = isAdvisoryChannel ? 'Já sou parceiro' : 'Já sou cliente';
    const registrationPhrase = isAdvisoryChannel
        ? 'Ainda não é parceiro?'
        : 'Ainda não tem cadastro?';

    if (
        props.message?.summary === 'You should receive an email shortly with further instructions.'
    ) {
        return (
            <>
                <NewHeader
                    hrefLogin={props.url.loginUrl}
                    hrefRegister={props.url.registrationUrl}
                />
                <Row className="pageRecoverPassword">
                    <ContentHeader
                        className="recover-header"
                        title="Recuperação de senha"
                        description="Enviamos o link de redefinição de senha para seu e-mail."
                    />
                    <div className="expired-message">
                        <InformationFilled32 />
                        <h1>Esse link expira em 5 minutos.</h1>
                    </div>
                </Row>
                {!window?.isWebView && <NewFooter />}
            </>
        );
    } else if (url.includes('cadastrar=true')) {
        return <></>;
    } else {
        return (
            <>
                <Helmet>
                    <title>{metaTags?.title}</title>
                    <meta name="description" content={metaTags?.description} />
                    <meta name="robots" content={metaTags?.robots} />
                    <link rel="canonical" href={metaTags?.url_canonical} />
                </Helmet>
                <NewHeader
                    hrefLogin={props.url.loginUrl}
                    hrefRegister={props.url.registrationUrl}
                />
                <Grid fullWidth condensed className="pageLogin">
                    <Form
                        onSubmit={onSubmit}
                        action={props.url.loginAction}
                        method="post"
                        className="form"
                    >
                        <Row className="login-container">
                            <Row className="title-container">
                                <h1 className="title">
                                    {financiamento ? 'Preencha para editar' : channelTypePhrase}
                                </h1>
                                {props.message?.summary ===
                                    'Action expired. Please start again.' && (
                                    <h4>Ação expirou o tempo limite.</h4>
                                )}
                            </Row>
                            <Row className="row-inputs">
                                <Input
                                    onChange={(e: any) => setUsername(e.target.value)}
                                    label={'E-mail ou CPF'}
                                    type="text"
                                    name="username"
                                    value={username}
                                    hasError={hasError}
                                    autoFocus={true}
                                    autoComplete="off"
                                />
                                <Input
                                    onChange={(e: any) => setPassword(e.target.value)}
                                    label={'Senha'}
                                    type="password"
                                    hasViewIcon
                                    name="password"
                                    value={password}
                                    hasError={hasError}
                                    errorMsg="Usuário ou senha não encontrados."
                                    autoComplete="off"
                                />
                            </Row>
                            <KeepLogged description="Mantenha-me conectado" name="rememberMe" />
                            <Row className="bottom-container">
                                <Button
                                    className="btn-login"
                                    label="Entrar"
                                    variant="primary"
                                    size="large"
                                    disabled={disable}
                                    type="submit"
                                    alt={'Entrar'}
                                    title={'Entrar'}
                                    onClick={() => onClickEntrar()}
                                />
                                <ButtonLinkGtm
                                    label="Esqueci minha senha"
                                    alt="Esqueci minha senha"
                                    title="Esqueci minha senha"
                                    className="btn-forgotPassword"
                                    onClick={() => onClickEsqueciMinhaSenha()}
                                    href={`${props.url.loginResetCredentialsUrl}${
                                        isWebView ? '&isWebView=true' : ''
                                    }`}
                                />
                                <Row className="row-register">
                                    <span>{registrationPhrase} </span>
                                    <ButtonLinkGtm
                                        className="btn-register"
                                        label="Cadastre-se"
                                        alt="Cadastre-se"
                                        title="Cadastre-se"
                                        onClick={() => onClickCadastro()}
                                        href={`${props.url.registrationUrl}${
                                            isWebView ? '&isWebView=true' : ''
                                        }`}
                                    />
                                </Row>
                                {/* <h4 className="connect-social-media">Ou acesse com:</h4>
                                <div className="icons">
                                    <div
                                        className="google"
                                        onClick={() =>
                                            (window.location.href = props.social?.providers
                                                ? props.social?.providers[0]?.loginUrl
                                                : '')
                                        }
                                    />
                                    {renderAppleIcon()}
                                </div> */}
                            </Row>

                            <InputPartners />
                        </Row>
                    </Form>
                </Grid>
                {!window?.isWebView && <NewFooter />}
            </>
        );
    }
}

export default Login;
