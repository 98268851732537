import { getParams } from '../..';
import { ButtonLinkGtm } from '../ButtonLinkGtm/ButtonLinkGtm';
import './LinkExpiredError.scss';

type Props = {
    href: any;
    link: string;
    title: string;
    alt: string;
    hasFakeSuccess?: boolean;
};

export const LinkExpiredError = ({ title, href, link, alt, hasFakeSuccess = false }: Props) => {
    const { isWebView } = getParams[0];

    return (
        <div data-testid="linkExpired-id" className="error-container">
            {hasFakeSuccess ? <div className="icon-success" /> : <div className="icon-error" />}
            <h1 className="title">{title}</h1>
            <ButtonLinkGtm
                alt={alt}
                title={title}
                label={link}
                className="btn-link"
                href={`${href}${isWebView ? '&isWebView=true' : ''}`}
            />
        </div>
    );
};
