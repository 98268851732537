import { KcContextBase } from 'keycloakify';
import { useEffect, useState } from 'react';
import { NewFooter } from '../../Component/NewFooter/NewFooter';
import { NewHeader } from '../../Component/NewHeader/NewHeader';
import PasswordReset from '../../Component/PasswordReset/PasswordReset';
import './LoginUpdatePassword.scss';

function LoginUpdatePassword(props: KcContextBase.LoginUpdatePassword) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        setError(false);
        setErrorMsg('');
        if (props.message?.summary === 'You need to change your password.') {
            setTitle('Recuperação de senha');
            setDescription('Digite e confirme sua nova senha:');
        } else {
            setTitle('Crie uma senha');
            setDescription('Escolha uma senha para acessar a e-agro');
            if (props.message?.summary === 'Invalid password: maximum length 12.') {
                setError(true);
                setErrorMsg('Senha deve conter no máximo 12 caracteres');
            } else if (
                props.message?.summary ===
                'Invalid password: must not be equal to any of last 5 passwords.'
            ) {
                setError(true);
                setErrorMsg('Senha não deve ser igual as últimas 5 senhas');
            }
        }
    }, [props.message?.summary]);

    return (
        <>
            <NewHeader hrefLogin={props.url.loginUrl} hrefRegister={'#'} />
            <PasswordReset
                title={title}
                description={description}
                action={props.url.loginAction}
                username={props.username}
                error={error}
                errorMsg={errorMsg}
            />
            {!window?.isWebView && <NewFooter />}
        </>
    );
}

export default LoginUpdatePassword;
