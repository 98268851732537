import { Column, Form, Grid, Row } from 'carbon-components-react';
import { KcContextBase } from 'keycloakify';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Button } from '../../Component/Button/Button';
import { ButtonRadio } from '../../Component/ButtonRadio/ButtonRadio';
import { ContentHeader } from '../../Component/ContentHeader/ContentHeader';
import { NewHeader } from '../../Component/NewHeader/NewHeader';
import { ActionType, TagManagerUtils, UserLoggedStatus } from '../../TagManagerUtils';
import './UserAuthentication.scss';
import { InputPartners } from '../../Component/InputPartners';
import { NewFooter } from '../../Component/NewFooter/NewFooter';

interface Test extends KcContextBase.LoginIdpLinkConfirm {
    url: {
        loginAction: string;
        resourcesPath: string;
        resourcesCommonPath: string;
        loginRestartFlowUrl: string;
        loginUrl: string;
        oauthAction?: string;
        firstBrokerLoginUrl?: string;
    };
}

function UserAuthentication(props: KcContextBase.LoginIdpLinkConfirm) {
    const [title, setTitle] = useState('');
    const [selectedOption, setSelectedOption] = useState('E-mail');
    const newProps: Test = props;

    const onSubmit = () => {
        if (newProps?.url?.oauthAction?.includes('registration')) {
            TagManagerUtils.interact(
                ActionType.Click,
                'confirmar-cadastro',
                `botao-continuar-${selectedOption}`,
                'cadastro',
                UserLoggedStatus.LoggedOut,
                'cadastro',
            );
        } else {
            TagManagerUtils.interact(
                ActionType.Click,
                'web',
                `Continuar`,
                'login',
                UserLoggedStatus.LoggedOut,
                'e-agro/autenticacao',
            );
        }
    };

    const items = [
        {
            label: 'E-mail',
            key: '2',
            value: 'BY_MAIL',
        },
        {
            label: 'SMS',
            key: '1',
            value: 'BY_PHONE',
        },
    ];

    useEffect(() => {
        if (newProps?.url?.oauthAction?.includes('registration')) {
            setTitle('Confirmar cadastro');
            TagManagerUtils.pageview(
                ActionType.PageView,
                'cadastro',
                UserLoggedStatus.LoggedOut,
                'cadastro',
                'web',
            );
        } else {
            setTitle('Autenticação de usuário');
            TagManagerUtils.pageview(
                ActionType.PageView,
                'login',
                UserLoggedStatus.LoggedOut,
                'e-agro/autenticacao',
                'web',
            );
        }
    }, []);

    const onClickRadioButton = (e: SyntheticEvent) => {
        const isRadioButtonPhone = (e.target as any).defaultValue === 'BY_PHONE';
        const selectedOption = isRadioButtonPhone ? 'SMS' : 'E-mail';
        setSelectedOption(selectedOption);
    };

    return (
        <>
            <NewHeader hrefLogin={props.url.loginUrl} hrefRegister={'#'} />
            <Grid className="pageUserAuthentication" fullWidth>
                <Column
                    sm={4}
                    md={8}
                    lg={{ span: 10, offset: 1 }}
                    xlg={{ span: 10, offset: 1 }}
                    max={{ span: 8, offset: 2 }}
                    className="column-container"
                >
                    <Form onSubmit={onSubmit} action={props.url.loginAction} method="post">
                        <Row className="row-content">
                            <ContentHeader
                                title={title}
                                description="Escolha por qual canal deseja receber o código de verificação."
                            />
                            <ButtonRadio
                                className="radio-btn"
                                orientation="vertical"
                                items={items}
                                defaultSelected={'BY_MAIL'}
                                name={'method_send'}
                                onClick={(e) => onClickRadioButton(e)}
                            />
                        </Row>
                        <Row className="row-button">
                            <Button
                                type="submit"
                                className="btn-enter"
                                onClick={onSubmit}
                                label="Continuar"
                                variant="primary"
                                alt={'Continuar'}
                                title={'Continuar'}
                                size="large"
                            />
                        </Row>

                        <InputPartners />
                    </Form>
                </Column>
            </Grid>
            {!window?.isWebView && <NewFooter />}
        </>
    );
}

export default UserAuthentication;
