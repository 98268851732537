/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo, useState } from 'react';
import * as S from './NewHeader.styles';
import { Close32, UserAvatarFilledAlt32 } from '@carbon/icons-react';
import { LateralMenu } from '../LateralMenu/LateralMenu';
import { ReactComponent as IconMenu } from '../../assets/icon-menu.svg';
import { TagManagerUtils, ActionType, UserLoggedStatus } from '../../TagManagerUtils';
import { useGetHeader } from '../../hook/useGetHeader';
import { useWindowDimensions } from '../../hook/useWindowDimensions';
import { validateArray } from '../../utils/validateArray';
import { useCategories } from '../../hook/useCategories';
import DropDownCategories from '../../Component/DropDown/MenuCategories';
import { useGetBlogFeatureFlag } from '../../hook/useGetPostStaticContent';
import { getParams } from '../..';

interface ICategory {
    id: string;
    name: string;
    children?: ICategory[];
}

export const NewHeader = ({ hrefLogin, hrefRegister }: Props) => {
    const [isOpenMenuHamburger, setIsOpenMenuHamburger] = useState(false);
    const { data: headerData } = useGetHeader().getHeader();
    const { width } = useWindowDimensions();
    const { data } = useCategories();
    const shouldShowMobileHeader = useMemo(() => {
        return width <= 1056;
    }, [width]);

    const { isWebView } = getParams[0];
    const auxHrefLogin = isWebView ? `${hrefLogin}&isWebView=true` : hrefLogin;
    const auxHrefRegister = isWebView ? `${hrefRegister}&isWebView=true` : hrefRegister;

    //  Blog Feature Flag:
    const { loading: flagLoading, error: flagError, data: flagData } = useGetBlogFeatureFlag();
    const isBlogEnabled = useMemo(() => {
        if (!flagLoading && !flagError) return flagData?.getPostStaticContent?.enabled || false;
        return false;
    }, [flagData, flagLoading, flagError]);

    const openMenu = () => {
        setIsOpenMenuHamburger(!isOpenMenuHamburger);
    };

    function tagManagerInteract(isRegister?: boolean) {
        TagManagerUtils.interact(
            ActionType.Click,
            TagManagerUtils.getActionLocation(),
            `${isRegister ? 'criar-conta' : 'entrar'}`,
            isRegister ? 'cadastro' : 'login',
            UserLoggedStatus.LoggedOut,
            'e-agro/header',
        );
    }

    const isCurrentRouteActive = (route: string): boolean => {
        return window?.location.pathname.toLowerCase() === route.toLowerCase() && route !== '#';
    };

    const menuTagInteract = (label: string) => {
        TagManagerUtils.interact(
            ActionType.Click,
            TagManagerUtils.getActionLocation(),
            `${label}`,
            'home',
            UserLoggedStatus.LoggedOut,
            'e-agro/home',
        );
    };

    return (
        <>
            <S.Header>
                <S.HeaderFirstRow>
                    {shouldShowMobileHeader && (
                        <S.HeaderFirstRowContainer>
                            {isOpenMenuHamburger && <Close32 fill="#0C881E" onClick={openMenu} />}
                            {!isOpenMenuHamburger && <IconMenu fill="#0C881E" onClick={openMenu} />}
                            <S.ImageBlock
                                role="img"
                                src={headerData?.onboard?.logo?.logo_e_agro_url}
                                aria-label={headerData?.onboard?.logo?.logo_e_agro_url_alt}
                                onClick={() => {
                                    window.location.href =
                                        process.env.REACT_APP_PUBLIC_URL?.toString() ?? '#';
                                }}
                            />
                            <UserAvatarFilledAlt32
                                fill="#0C881E"
                                className="smaller-icon"
                                onClick={() => {
                                    tagManagerInteract();
                                    window.location.href = auxHrefLogin;
                                }}
                            />
                        </S.HeaderFirstRowContainer>
                    )}
                    {!shouldShowMobileHeader && (
                        <S.HeaderFirstRowContainer>
                            <S.HeaderFirstRowContainerLeft>
                                <S.Image
                                    logo_url={headerData?.onboard?.logo?.logo_e_agro_url}
                                    aria-label={headerData?.onboard?.logo?.logo_e_agro_url_alt}
                                    onClick={() => {
                                        window.location.href =
                                            process.env.REACT_APP_PUBLIC_URL?.toString() ?? '#';
                                    }}
                                />
                                <a
                                    className={`link-header ${
                                        isCurrentRouteActive('/sobre') ? 'active-route' : ''
                                    }`}
                                    href={`${process.env.REACT_APP_PUBLIC_URL}sobre`}
                                    onClick={() => menuTagInteract('quem-somos')}
                                >
                                    Quem Somos
                                </a>
                                <a
                                    className={`link-header ${
                                        isCurrentRouteActive('/simulacao-de-credito')
                                            ? 'active-route'
                                            : ''
                                    }`}
                                    href={`${process.env.REACT_APP_PUBLIC_URL}simulacao-de-credito`}
                                    onClick={() => menuTagInteract('linhas-de-credito')}
                                >
                                    Linhas de crédito
                                </a>
                                <S.CategoryContainer id="categs">
                                    <DropDownCategories
                                        isFromHome={true}
                                        // closeRegionOnCategoriesHover={() => toggleRegionModal()}
                                        // showRegionModal={context?.showRegionModal as boolean}
                                        categoriesData={
                                            validateArray(
                                                data?.getCategoriesAndSubcategories?.categories
                                                    ?.items as ICategory[],
                                            )[0]?.children
                                        }
                                    />
                                </S.CategoryContainer>
                                {isBlogEnabled && (
                                    <a
                                        className={`link-header ${
                                            isCurrentRouteActive('/blog') ? 'active-route' : ''
                                        }`}
                                        href={`${process.env.REACT_APP_PUBLIC_URL}blog`}
                                        onClick={() => menuTagInteract('blog')}
                                    >
                                        Blog
                                    </a>
                                )}
                            </S.HeaderFirstRowContainerLeft>
                            <S.HeaderFirstRowContainerRight>
                                <>
                                    <S.ButtonLinkGtm
                                        label="Entrar"
                                        size="large"
                                        variant="primary"
                                        className="btn-signin"
                                        alt="Entrar"
                                        title="Entrar"
                                        onClick={() => {
                                            tagManagerInteract();
                                            window.location.href = auxHrefLogin;
                                        }}
                                    />
                                    <S.ButtonLinkGtm
                                        label="Criar conta"
                                        size="large"
                                        variant="secondary"
                                        className="btn-signup"
                                        alt="Criar conta"
                                        title="Criar conta"
                                        onClick={() => {
                                            tagManagerInteract(true);
                                            window.location.href = auxHrefRegister;
                                        }}
                                    />
                                </>
                            </S.HeaderFirstRowContainerRight>
                        </S.HeaderFirstRowContainer>
                    )}
                </S.HeaderFirstRow>
            </S.Header>

            {/* {isOpenMenuHamburger && <LateralMenu onClickDiv={openMenu} />} */}
            {isOpenMenuHamburger && (
                <LateralMenu
                    menuItems={
                        validateArray(
                            data?.getCategoriesAndSubcategories?.categories?.items as ICategory[],
                        )[0]?.children
                    }
                    onClickDiv={openMenu}
                />
            )}
        </>
    );
};

type Props = {
    hrefLogin: any;
    hrefRegister: any;
};
