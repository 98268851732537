import { Form, Grid, Row } from 'carbon-components-react';
import { KcContextBase } from 'keycloakify';
import { useEffect, useState } from 'react';
import { Button } from '../../Component/Button/Button';
import { ContentHeader } from '../../Component/ContentHeader/ContentHeader';
import { NewHeader } from '../../Component/NewHeader/NewHeader';
import Input from '../../Component/Input/Input';
import { ActionType, TagManagerUtils, UserLoggedStatus, Event } from '../../TagManagerUtils';
import './ResetPasswordEmailCPF.scss';
import { NewFooter } from '../../Component/NewFooter/NewFooter';

function ResetPasswordEmailCPF(props: KcContextBase.LoginResetPassword) {
    const [disabled, setDisabled] = useState(true);

    const handleDisabled = () => {
        setDisabled(false);
    };

    const onSubmit = () => {
        setDisabled(false);
    };

    useEffect(() => {
        TagManagerUtils.pageview(
            ActionType.PageView,
            'recuperacao-senha-login',
            UserLoggedStatus.LoggedOut,
            'e-agro/login/recuperacao-senha-login',
            TagManagerUtils.getActionLocation(),
        );
    }, []);

    function onClickContinuar() {
        TagManagerUtils.interact(
            ActionType.Click,
            'botao',
            `botao-continuar`,
            'recuperacao-senha-login',
            UserLoggedStatus.LoggedOut,
            'e-agro/login/recuperacao-senha-login',
        );
    }

    return (
        <>
            <NewHeader hrefLogin={props.url.loginUrl} hrefRegister={'#'} />
            <Grid className="pageResetPassword">
                <Row className="row-content">
                    <Form onSubmit={onSubmit} action={props.url.loginAction} method="post">
                        <ContentHeader
                            className="content-header"
                            title="Recuperação de senha"
                            description="Digite seu e-mail ou CPF para redefinir sua senha"
                        />
                        <Input
                            label={'E-mail ou CPF'}
                            placeholder="Digite aqui o seu e-mail ou CPF"
                            type="text"
                            name="username"
                            onChange={() => {
                                handleDisabled();
                            }}
                            required
                        />
                        <div className="btn-container">
                            <Button
                                disabled={disabled}
                                label="Continuar"
                                type="submit"
                                variant="primary"
                                size="large"
                                alt={'Continuar'}
                                title={'Continuar'}
                                onClick={() => onClickContinuar()}
                            ></Button>
                        </div>
                    </Form>
                </Row>
            </Grid>
            {!window?.isWebView && <NewFooter />}
        </>
    );
}

export default ResetPasswordEmailCPF;
